<!--在线监管-实时管理-小时折算浓度-->
<template>
  <el-dialog
    title="小时折算浓度"
    :visible.sync="isVisible"
    @close="handleClose"
    center>
    <div class="hourTitle">{{ locale.Locale }}</div>
    <el-row>
      <el-date-picker
        v-model="date"
        type="date"
        @change="getData"
        value-format="timestamp"
        placeholder="选择日期"/>
    </el-row>

    <el-table :data="dataList">
      <el-table-column type="index" label="序号" align="center" width="55"/>
<!--  property="hour"就是渲染拿到的数据，数据里面有个名为hour的，property可以写成prop    -->
      <el-table-column
        property="hour"
        label="时间"
        width="150">
      </el-table-column>
      <el-table-column
        property="emissions_conc"
        label="油烟折算浓度"
        align="center"/>
      <el-table-column
        property="granule_conc"
        align="center"
        label="颗粒物折算浓度"/>
      <el-table-column
        property="hydrocarbon_conc"
        align="center"
        label="非甲烷折算浓度"/>
      <el-table-column
        property="status"
        align="center"
        label="排放状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" type="success">达标</el-tag>
          <div v-else-if="scope.row.status === '-'"></div>
          <el-tag v-else type="danger">超标</el-tag>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import {ifNull, isEmptyObj} from "@/util";
import dayjs from "dayjs";

export default {
  name: "IntimeHourDataDialog",
  props: ['locale', 'visible'],

  data() {
    return {
      dataList: [],
      isVisible: this.visible,
      date: null
    }
  },
  watch: {
    locale: {
      handler: function (val) {
        this.date = dayjs().hour(0).minute(0).second(0).unix() * 1000
        this.getData()
      }
    },
    visible: {
      handler: function (val) {
        this.isVisible = val
      }
    }
  },
  methods: {
    getData() {
      this.dataList = []
      let param = {
        at: this.date ? this.date / 1000 : null,
        mn: this.locale.Id
      }
      this.$get('admin/listTenHourData', param).then(res => {
        res = res.list
        let i = 0, j = 0
        while (i < 24) {
          if (res[j] === undefined || res[j].hour !== i) {
            let item = {
              hour: i + '~' + (i + 1) + '点',
              emissions_conc: '-',
              granule_conc: '-',
              hydrocarbon_conc: '-',
              status: '-'
            }
            this.dataList.push(item)
          } else {
            res[j].hour = res[j].hour + '-' + (res[j].hour + 1) + '点'
            this.dataList.push(res[j])
            j++
          }
          i++
        }


      })
    },
    handleClose() {
      this.isVisible = false
      this.$emit("close")
    }
  }
}
</script>
<style scoped lang="scss">
.hourTitle {
  margin-bottom: 10px;
  font-weight: bold;
}
</style>
