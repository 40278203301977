<!--在线监管-实时管理-->
<template>
  <section >
    <!-- 工具条 -->
    <el-row>
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
        <el-form :inline="true" :model="filter" size="mini">
          <el-form-item>
            <el-cascader
              v-model="filter.owner"
              @change="getData"
              placeholder="所属单位"
              :options="customerTree"
              :props="orgProps"
              clearable
              filterable
            />
          </el-form-item>
          <el-form-item>
            <el-select
              placeholder="排放状态"
              v-model="filter.status"
              @change="getData"
              clearable
            >
              <el-option label="达标" value="NORMAL"/>
              <el-option label="超标" value="EXCESS"/>
              <el-option label="离线" value="DOWN"/>
              <el-option label="异常离线" value="OFF"/>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              placeholder="整体状态"
              v-model="filter.onlineStatus"
              @change="getData"
              clearable
            >
              <el-option label="绿" value="1"/>
              <el-option label="灰" value="2"/>
              <el-option label="黑" value="3"/>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-radio-group v-model="filter.typ" @change="handleType">
              <el-radio :label="1">监控设备</el-radio>
              <el-radio :label="2">监测设备</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleRefresh">刷新</el-button>
            <el-button
              type="primary"
              @click="openExportSizeInput"
              :loading="exportLoading"
            >导出
            </el-button
            >
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="columnVisible"
              v-if="filter.typ === 2"
              multiple
              placeholder="请选择"
              collapse-tags
              @change="afterChangeColumnVisiable"
            >
              <el-button @click="selectAll" size="mini" type="text" style="margin-left : 15px">全选</el-button>
              <el-option
                v-for="item in columnVisibleOptions1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>

            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="columnVisible"
              v-if="filter.typ === 1"
              multiple
              placeholder="请选择"
              collapse-tags
              @change="afterChangeColumnVisiable"
            >
              <el-button @click="selectAll" size="mini" type="text" style="margin-left : 15px">全选</el-button>
              <el-option
                v-for="item in columnVisibleOptions2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <table class="device-info">
        <tr>
          <th>监测点数</th>
          <th>{{ filter.typ == 1 ? "监控设备数" : "监测设备数" }}</th>
          <th>当日在线设备数</th>
        </tr>
        <tr>
          <th>{{ localeNum }}</th>
          <th>{{ deviceNum }}</th>
          <th>{{ onlineNum }}</th>
        </tr>
      </table>
    </el-row>
    <!-- 列表 -->
    <el-row>
      <el-col :span="24">
        <el-table
          ref="table"
          :data="datalist.content"
          @sort-change="handleSort"
          size="mini"
          :max-height="clientHeight"
          border
          highlight-current-row
          v-loading="loading"
          style="width: 100%"
        >
          <el-table-column type="index" label="#" align="center" width="55"/>
          <el-table-column
            prop="Locale"
            v-if="columnVisible.includes('1')"
            label="监测点"
            :width="filter.typ === 2 ? 120 : ''"
            show-overflow-tooltip
            header-align="center"
          >
<!--        show-overflow-tooltip内容过长是隐藏    -->
          </el-table-column>
          <el-table-column
            prop="Addr"
            v-if="columnVisible.includes('2')"
            label="安装地址"
            show-overflow-tooltip
            :min-width="filter.typ === 2 ? 200 : ''"
            header-align="center"
          />
          <el-table-column
            prop="Id"
            v-if="columnVisible.includes('3')"
            sortable="custom"
            label="设备编码"
            show-overflow-tooltip
            :min-width="filter.typ === 2 ? 200 : ''"
            align="center"
            header-align="center"
          />
          <!-- <el-table-column prop='Owner' :formatter="customerFormatter" label='所属单位' show-overflow-tooltip width='230' header-align='center' /> -->
          <el-table-column
            prop="AcquitAt"
            v-if="columnVisible.includes('4')"
            :formatter="dateFormat"
            sortable
            label="采集时间"
            show-overflow-tooltip
            :min-width="filter.typ === 2 ? 200 : ''"
            align="center"
            header-align="center"
          />
          <el-table-column
            v-if="columnVisible.includes('5')"
            width="90"
            label="风机状态"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ FanFilterStatusFormatter(scope.row, 0) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="columnVisible.includes('6')"
            width="100"
            label="风机电流"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ electricityFormatter(scope.row, 'fan') }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="columnVisible.includes('7')"
            width="90"
            label="净化器状态"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ FanFilterStatusFormatter(scope.row, 1) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="columnVisible.includes('8')"
            width="100"
            label="净化器电流"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ electricityFormatter(scope.row, 'filter') }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="columnVisible.includes('9')"
            width="60"
            label="风速"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ (scope.row.Status === "OFF" || scope.row.Velocity === 0) ? "-" : scope.row.Velocity }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="columnVisible.includes('19')"
            width="60"
            label="风量"
            align="center"
            header-align="center"
          ></el-table-column>
          <el-table-column
            v-if="columnVisible.includes('10')"
            width="60"
            label="温度"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{
                  (scope.row.Status === "OFF" || scope.row.Temperature === 0) ? "-" : scope.row.Temperature
                }}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="60"
            v-if="columnVisible.includes('11')"
            label="湿度"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ (scope.row.Status === "OFF" || scope.row.Moisture === 0) ? "-" : scope.row.Moisture }}</span>
            </template>
          </el-table-column>
          <template v-if="filter.typ === 2">
            <el-table-column
              v-if="columnVisible.includes('12')"
              prop="EmissionsConc"
              label="油烟实时浓度(mg/m³)"
              min-width="160"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.Status === "OFF" ? "-" : scope.row.EmissionsConc }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columnVisible.includes('13')"
              prop="CEmissions"
              label="油烟折算浓度(mg/m³)"
              min-width="160"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.Status === "OFF" ? "-" : scope.row.CEmissions }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columnVisible.includes('14')"
              prop="GranuleConc"
              label="颗粒物实时浓度(mg/m³)"
              min-width="160"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.Status === "OFF" ? "-" : scope.row.GranuleConc }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columnVisible.includes('15')"
              prop="CGranule"
              label="颗粒物折算浓度(mg/m³)"
              min-width="160"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.Status === "OFF" ? "-" : scope.row.CGranule }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columnVisible.includes('16')"
              prop="HydrocarbonConc"
              label="非甲烷总烃实时浓度(mg/m³)"
              min-width="200"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.Status === "OFF" ? "-" : scope.row.HydrocarbonConc }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columnVisible.includes('17')"
              prop="CHydrocarbon"
              label="非甲烷总烃折算浓度(mg/m³)"
              min-width="200"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.Status === "OFF" ? "-" : scope.row.CHydrocarbon }}</span>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            v-if="columnVisible.includes('18')"
            label="排放状态"
            width="80"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <img height="40" width="40" :src="getImg('Status', scope.row.Status)" alt="排放状态"/>
            </template>
          </el-table-column>
          <el-table-column
            v-if="columnVisible.includes('20')"
            label="整体状态"
            width="80"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <img height="40" width="40" :src="getImg('OnlineStatus', scope.row.OnlineStatus)" alt="整体状态"/>
            </template>
          </el-table-column>
<!--          操作功能-->
          <el-table-column
            fixed="right"
            label="操作"
            align="center"
            header-align="center"
            width="180"
          >
            <template slot-scope="scope">
              <i
                title="一点一档"
                @click="handleFileClick(scope.row)"
                class="opt el-icon-document"
                @on-close="handleClose(false)"
              />
              <i
                title="查看每分钟数据"
                @click="handleShowIntime(scope.row, false)"
                class="opt el-icon-notebook-2"
              />
              <i
                title="查看10min浓度"
                v-if="filter.typ === 2"
                @click="handleShowIntime(scope.row, true)"
                class="opt el-icon-s-data"
              />
              <i
                title="查看小时折算浓度"
                class="opt iconfont icon-data-view"
                @click="handleHourDataVisible(scope.row)"/>
              <i title="监测点设备管理" class="opt el-icon-s-order"
                 @click="handleClickMaintenanceManagement(scope.row)">
              </i>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 工具条页码 -->
    <el-row>
      <el-col :span="24" class="toolbar">
        <el-pagination
          small
          background
          @size-change="(v) => handlePageChange(v, 'size', true)"
          @current-change="(v) => handlePageChange(v, 'page', true)"
          :current-page.sync="filter.page"
          :page-sizes="filter.pageSizes"
          layout="total, sizes, prev, pager, next, jumper"
          :total="datalist.total"
          style="display: inline-block; margin-left: 15px"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <!--操作功能的界面展示-->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible"
      width="85%"
      @close="handlerClose">
      <span slot="title"><strong>{{ owner }}</strong></span>
      <el-form :inline="true" size="mini">
        <el-form-item v-if="showTyp">
          <el-select placeholder="时间选择" v-model="timeType">
            <el-option label="按天" value="1"/>
            <el-option label="时间段" value="2"/>
          </el-select>
        </el-form-item>
        <el-form-item v-if="timeType == 1">
          <el-date-picker
            @change="handleDateSel"
            v-model="selDate"
            type="date"
            value-format="timestamp"
            placeholder="日期选择"
          />
        </el-form-item>
        <template v-else-if="timeType == 2">
          <el-form-item>
            <el-date-picker
              type="date"
              v-model="startDate"
              value-format="yyyy-MM-dd"
              placeholder="开始日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              type="date"
              v-model="endDate"
              value-format="yyyy-MM-dd"
              placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="handleDateSel"
            >查询
            </el-button
            >
          </el-form-item>
        </template>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-download"
            @click="handleExport"
          >导出
          </el-button
          >
          <!-- <el-button type="danger" v-if="showTyp"  @click="ExportSavePdf('文件名称','到处时间')">导出PDF</el-button> -->
        </el-form-item>
      </el-form>
      <template v-if="showTyp">
        <div id="pdfCentent">
          <div class="chart-line">
            <chart :options="tenMinOption" autoresize/>
          </div>
        </div>
      </template>
      <template v-else>
        <el-table
          :data="intimeData.content"
          size="mini"
          border
          highlight-current-row
          v-loading="loading"
          :max-height="clientHeight"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="序号"
            align="center"
            width="55"
          />
          <el-table-column
            prop="acquit_at"
            :formatter="dateFormat"
            label="采集时间"
            width="140"
            align="center"
            header-align="center"
          />
          <el-table-column
            label="风机状态"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.fan_status | linkFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="风机电流(A)"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.fan_current != -1 ? scope.row.fan_current : '-' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :formatter="ffFormatter"
            label="净化器状态"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.filter_status | linkFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :formatter="ffFormatter"
            label="净化器电流(A)"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.pur_current != -1 ? scope.row.pur_current : '-'}}</span>
            </template>
          </el-table-column>

          <template v-if="filter.typ === 2">
            <el-table-column
              prop="emissions_conc"
              label="油烟浓度(mg/m³)"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span :class="scope.row.emissions_conc > 2 ? 'red' : ''">{{ scope.row.emissions_conc }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="granule_conc"
              label="颗粒物浓度(mg/m³)"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span :class="scope.row.granule_conc > 5 ? 'red' : ''">{{ scope.row.granule_conc }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="hydrocarbon_conc"
              label="非甲烷总烃浓度(mg/m³)"
              align="center"
              header-align="center"
            >
              <template slot-scope="scope">
                <span :class="scope.row.hydrocarbon_conc > 10 ? 'red' : ''">{{ scope.row.hydrocarbon_conc }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column prop='velocity' label='流速' align='center' header-align='center' />
            <el-table-column prop='temperature' label='温度' align='center' header-align='center' />
            <el-table-column prop='moisture' label='湿度' align='center' header-align='center' /> -->
          </template>
          <template v-else>
            <el-table-column
              prop="poweroff"
              label="电源关闭指令"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="fc1"
              label="电源1故障代码"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="cv1"
              label="电源1电流值"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="fc2"
              label="电源2故障代码"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="cv2"
              label="电源2电流值"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="fc3"
              label="电源3故障代码"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="cv3"
              label="电源3电流值"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="fc4"
              label="电源4故障代码"
              align="center"
              header-align="center"
            />
            <el-table-column
              prop="cv4"
              label="电源4电流值"
              align="center"
              header-align="center"
            />
          </template>
          <el-table-column
            label="设备状态"
            width="70"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <p
                v-if="scope.row.emissions_conc*1 > 2 || scope.row.granule_conc*1 > 5 || scope.row.hydrocarbon_conc*1 > 10">
                超标</p>
              <p v-else>达标</p>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          small
          background
          @size-change="(v) => handlePageChange(v, 'intimeSize', false)"
          @current-change="(v) => handlePageChange(v, 'intimePage', false)"
          :current-page.sync="filter.intimePage"
          :page-sizes="filter.pageSizes"
          layout="total, sizes, prev, pager, next, jumper"
          :total="intimeData.total"
          style="display: inline-block; margin-left: 15px"
        />
      </template>
    </el-dialog>
    <!-- 生成过的一点一档记录 -->
    <el-dialog
      title="一点一档记录"
      :visible.sync="pdfRecordVisible"
      width="800px"
    >
      <el-table
        :data="pdfList"
        size="mini"
        border
        highlight-current-row
        :max-height="clientHeight - 40"
        style="width: 100%"
      >
        <el-table-column
          prop="BeginDate"
          label="开始时间"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="EndDate"
          label="结束时间"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="CreatedAt"
          label="生成时间"
          align="center"
          header-align="center"
        />
        <el-table-column

          label="操作"
          align="center"
          header-align="center">

          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="handleLook(scope.row)"
            >查看
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click.native="pdfRecordVisible = false"
        >关闭
        </el-button
        >
      </div>
    </el-dialog>
    <!--    一点一档界面-->
    <el-dialog
      :visible.sync="isShowNotificationDialog"
      style="position: fixed !important;
      top: 50% ;
      left: 50% ;
      width: 120%;
      height: 120%;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      transform: translate(-50%, -50%) !important;
      z-index: 9999 !important;"
    >
      <div style="max-height: calc(100vh - 200px);overflow-y: auto;">
        <point-info-dialog
          :device="device"
        ></point-info-dialog>
      </div>
    </el-dialog>
    <!--    监测点设备管理页面-->
<!--      去掉dialog的title  :title="managementDialog.title"-->

      <el-dialog
      :visible.sync="managementDialog.visible"
      @close="handlerClose"
      width="80%"

    >
        <div ref="pointInformationDialog" >
      <h2 style="text-align: center;font-size: 28px;">监测点设备信息及事件记录</h2>
      <h2>一、设备信息</h2>
      <el-table
        v-loading="managementDialog.loading"
        :data="managementDialog.deviceData"
        style="width:95%;margin: 0 auto"
      >
        <el-table-column type="index" label="序号" align="center" width="55"/>
        <el-table-column prop='MN' label='设备编码' align='center' width='170' header-align='center'/>
        <el-table-column prop='Locale.Name' label='监测点' align='center' min-width='80' header-align='center'/>
        <el-table-column prop='Owner' label='所属单位' show-overflow-tooltip align="center" min-width='100'
                         header-align='center' :formatter="customerFormatter"/>
        <el-table-column prop='Subtype' label='设备类型' :formatter="subTypeFormatter" align='center' width="100"
                         header-align='center'/>
        <el-table-column prop='Model' label='型号' :formatter="modelFormatter" align='center' width="100"
                         header-align='center'/>
        <el-table-column prop='Brand' label='品牌' align='center' width="100" header-align='center'/>
        <el-table-column prop='Sim' label='SIM卡' align='center' width="100" header-align='center'/>
      </el-table>
<!--      上下线记录-->
      <h2>二、上下线记录</h2>
      <el-table
        v-loading="managementDialog.loading"
        :data="record.list"
        style="width:95%;margin: 0 auto"
      >
        <el-table-column type="index" label="序号" align="center" width="55"/>
        <el-table-column align="center" prop="mn" label="设备编号"></el-table-column>
        <el-table-column
          align="center"
          prop="create_at"
          label="提交时间"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="user_name"
          label="提交人"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="reason"
          label="上下线原因"
          width="150"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="status_info"
          label="处理状态"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="handle_at"
          label="处理日期"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作">
          <template slot-scope="scope">
            <i
              title="查看"
              @click="handleView(scope.row)"
              class="opt el-icon-orange el-icon-view"
            />
          </template>
        </el-table-column>
      </el-table>
      <!--      上下线记录查看弹窗组件-->
      <offline-view-dialog
        :id="id"
        :visible.sync="viewVisible"
        :mode="viewMode"
        @handleClose="handleViewClose"
      />
<!--      红码处理记录-->
      <h2>三、红码处理记录</h2>
      <el-table
        v-loading="managementDialog.loading"
        :data="redCode"
        style="width:95%;margin: 0 auto"
      >
        <el-table-column type="index" label="序号" align="center" width="55"/>
        <el-table-column align="center" prop="StartAt" label="起始日期" width="100" :formatter="dateFormat"></el-table-column>
        <el-table-column
          align="center"
          prop="ExcessDays"
          label="超标天数"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="FilterAbnormallyUsedDays"
          label="不正常使用净化器天数"
          width="190"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.FilterAbnormallyUsedDays || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="OfflineCausedByShopDays"
          label="店家原因离线天数"
          width="150"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.OfflineCausedByShopDays || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="VerifyAt"
          :formatter="dateFormat"
          label="核实日期"

        ></el-table-column>
        <el-table-column
          align="center"
          prop="Verifier"
          label="核实人员"
          width="90"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="Cause"
          label="核实详情"

        ></el-table-column>
        <el-table-column
          align="center"
          prop="HandleAt"
          :formatter="dateFormat"
          label="处理日期"
          width="100"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="Handler"
          label="处理人员"
          width="90"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="Resolution"
          label="处理措施"

        ></el-table-column>
        <el-table-column
          width="50"
          align="center"
          label="操作">
          <template slot-scope="scope">
            <i
              title="查看"
              @click="handleShowHealthCodeRecord(scope.row)"
              class="opt el-icon-orange el-icon-view"
            />
          </template>
        </el-table-column>
      </el-table>
      <!--      红码处理记录查看弹窗-->
      <el-dialog width="60%" :visible.sync="HealthCodeRecordDialog.visible" title="红码处理记录" :center="true">
        <el-table
          :data="HealthCodeRecordDialog.data"
          v-loading="HealthCodeRecordDialog.loading"
          size="mini"
          border
        >
          <el-table-column type="index" label="#" align="center" width="55"/>
          <el-table-column
            prop="StartAt"
            :formatter="dateFormat"
            label="起始日期"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            prop="ExcessDays"
            label="超标天数"
            width="100"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.ExcessDays || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="FilterAbnormallyUsedDays"
            label="不正常使用净化器天数"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.FilterAbnormallyUsedDays || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="OfflineCausedByShopDays"
            label="店家原因离线天数"
            align="center"
            header-align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.OfflineCausedByShopDays || "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="VerifyAt"
            :formatter="dateFormat"
            label="核实日期"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            prop="Verifier"
            label="核实人员"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            prop="Cause"
            label="核实结果"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            prop="HandleAt"
            :formatter="dateFormat"
            label="处理日期"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            prop="Handler"
            label="处理人员"
            align="center"
            width="100"
            header-align="center"
          />
          <el-table-column
            prop="Resolution"
            label="处理结果"
            align="center"
            width="100"
            header-align="center"
          />
        </el-table>
      </el-dialog>

<!--      报警管理记录-->
      <h2>四、报警管理记录</h2>
      <div style="margin-bottom: 17px">
        <el-table
          :data="alarmManagement.list"
          border
          size="mini"
          style="width: 97%"
        >
          <el-table-column type="index" label="序号" align="center" width="55"/>
          <el-table-column align="center" prop="acquit_at" label="报警时间" width="150"></el-table-column>
          <el-table-column
            align="center"
            prop="event_type"
            label="报警类型"
            width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="event_status"
            label="事件状态"
            width="80"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="process_at"
            label="核实时间"
            width="150"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="process_reason"
            label="报警原因"

          ></el-table-column>
          <el-table-column
            align="center"
            prop="process_info"
            label="核实结果"

          ></el-table-column>
          <el-table-column
            align="center"
            prop="rectify_desc"
            label="整改状态"
            width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="rectify_message"
            label="整改情况"

          ></el-table-column>
          <el-table-column
            align="center"
            prop="handle_at"
            label="执法处理时间"
            width="130"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="solution"
            label="执法处理情况"

          ></el-table-column>
          <el-table-column
            align="center"
            label="操作"
            width="55">
            <template slot-scope="scope">
              <i
                title="查看"
                @click="handleCodeView(scope.row.id, 'view')"
                class="opt el-icon-orange el-icon-view"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--      报警管理记录查看弹窗-->
      <alarm-view-dialog ref="alarmViewDialog" @on-refresh="getData"/>
<!--运维记录-->
      <h2>五、运维记录</h2>
      <el-table
        v-loading="managementDialog.loading"
        :data="managementDialog.maintenanceData.content"
        style="width:95%;margin:0 auto"
      >
        <el-table-column type="index" label="序号" align="center" width="50"/>
        <el-table-column
          prop="CreateAt"
          :formatter="managementDateFormatter"
          label="派单日期"
          align="center"
          width="100"
          header-align="center"
        />
        <el-table-column
          prop="MN"
          label="设备编号"
          show-overflow-tooltip
          align="center"
          min-width="200"
          header-align="center"
        />
        <el-table-column
          prop="Typ"
          :formatter="maintenanceTypeFormatter"
          label="维护类型"
          align="center"
          width="80"
          header-align="center"
        />
        <el-table-column
          prop="MaintainerName"
          label="运维人员"
          align="center"
          width="100"
          header-align="center"
        />
        <el-table-column
          prop="MaintainAt"
          :formatter="managementDateFormatter"
          label="处理日期"
          align="center"
          width="100"
          header-align="center"
        />
        <el-table-column
          prop="Handler"
          label="处理人员"
          align="center"
          width="200"
          header-align="center"
        />
        <el-table-column
          prop="Status"
          label="操作状态"
          :formatter="maintenanceStatusFormatter"
          align="center"
          width="100"
          header-align="center"
        />
        <el-table-column
          prop="ExamStatus"
          label="审核状态"
          align="center"
          width="80"
          header-align="center">
          <template slot-scope="scope">
            <el-tag type="warning" v-if="scope.row.ExamStatus === 0">待审核</el-tag>
            <el-tag type="success" v-else-if="scope.row.ExamStatus === 1">通过</el-tag>
            <el-tag type="danger" v-else-if="scope.row.ExamStatus === 2">拒绝</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          align="center"
          fixed="right"
          min-width="120"
          header-align="center"
        >
          <template slot-scope="scope">
              <i--
                title="查看"
                @click="handleManagementLook(scope.row)"
                class="opt el-icon-orange el-icon-view"
              />
          </template>
        </el-table-column>
      </el-table>
<!--      页数组件-->
<!--      <el-pagination-->
<!--        small-->
<!--        background-->
<!--        @current-change="(v) => this.filter.page = v"-->
<!--        :current-page.sync="filter.page"-->
<!--        :page-sizes="filter.pageSizes"-->
<!--        :total="this.managementDialog.maintenanceData.total"-->
<!--        style="width: 500px;margin:16px auto 0 auto"-->
<!--      >-->
<!--      </el-pagination>-->
<!--        运维记录查看详情-->
      <el-dialog
        style="top:-100px;"
        width="550px"
        title="查看详情"
        :visible.sync="managementDialog.innerVisible"
        append-to-body>
        <el-form
          :model="managementDialog.innerData"
          size="mini"
          label-width="108px"
          label-position="left"
          :disabled="true"
        >
          <el-row>
            <el-col>
              <el-form-item prop="LocaleName" label="监测点名称">
                <el-input
                  v-model="managementDialog.innerData.LocaleName"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item prop="LocalAddr" label="监测点地址">
            <el-input
              v-model="managementDialog.innerData.Addr"
              placeholder="无"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="Contact" label="联系人及电话">
            <el-input
              v-model="managementDialog.innerData.Contact"
              disabled
              placeholder="无"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="Mobile" label=" ">
            <el-input
              v-model="managementDialog.innerData.Mobile"
              disabled
              placeholder="无"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="DeviceId" label="维护设备">
            <el-input
              v-model="managementDialog.innerData.DeviceId"
            ></el-input>
          </el-form-item>
          <el-row>
            <el-form-item prop="Typ" label="维护类型">
              <span v-if="managementDialog.innerData.Typ === 1">维修</span>
              <span v-else-if="managementDialog.innerData.Typ === 2">移机</span>
              <span v-else-if="managementDialog.innerData.Typ === 3">拆机</span>
              <span v-else-if="managementDialog.innerData.Typ === 6">维保</span>
              <span v-else-if="managementDialog.innerData.Typ === 7">超标巡查</span>
              <span v-else-if="managementDialog.innerData.Typ === 8">异常离线巡查</span>
              <span v-else-if="managementDialog.innerData.Typ === 9">换机</span>
              <span v-else></span>
            </el-form-item>
            <el-form-item prop="NewLocaleId" label="新监测点名称">
              <el-input
                v-model="managementDialog.innerData.NewLocaleName"
              ></el-input>
            </el-form-item>
            <el-form-item prop="Subtype" label="设备类型">
              <el-input
                v-model="managementDialog.innerData.Subtype"
              ></el-input>
            </el-form-item>
          </el-row>
          <el-form-item prop="Remark" label="其他原因及目前状态备注">
            <el-input
              placeholder="其他原因及处理措施备注"
              type="textarea"
              :rows="2"
              autosize
              v-model="managementDialog.innerData.Remark"
            />
          </el-form-item>
          <el-form-item
            prop="Status"
            label="处理结果"
          >
            <span v-if="managementDialog.innerData.Status === 1">已完成</span>
            <span v-else-if="managementDialog.innerData.Status === 2">待审核</span>
          </el-form-item>
          <el-form-item
            prop="Resolution"
            label="处理措施"
          >
            <el-input
              type="textarea"
              :rows="2"
              autosize
              v-model="managementDialog.innerData.Resolution"
            ></el-input>
          </el-form-item>
          <el-form-item prop="MaintainerName" label="运维人员">
            <el-input
              v-model="managementDialog.innerData.MaintainerName"
            ></el-input>
          </el-form-item>
          <el-form-item prop="CreateAt" label="派单日期">
            <el-date-picker
              disabled
              placeholder="派单日期"
              type="date"
              value-format="timestamp"
              format="yyyy-MM-dd"
              v-model="managementDialog.innerData.CreateAt"
            ></el-date-picker>
          </el-form-item>
          <el-form-item prop="CreateAt" label="处理日期">
            <el-date-picker
              placeholder="处理日期"
              type="date"
              value-format="timestamp"
              format="yyyy-MM-dd"
              v-model="managementDialog.innerData.MaintainAt"
            ></el-date-picker>
          </el-form-item>
          <el-form-item prop="Pos" label="定位">
            <baidu-map
              class="bm-view"
              :center="mapCenter"
              :zoom="mapZoom"
              ak="770b4c07458f53896ff0abd948755e20"
              @ready="handleMapReady"
            >
              <bm-geolocation
                anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                :showAddressBar="true"
                :autoLocation="true"
                @locationSuccess="getPosition"
              ></bm-geolocation>
            </baidu-map>
            <span>{{ managementDialog.innerData.Position ? managementDialog.innerData.Position : '暂无定位' }}</span>
          </el-form-item>
          <el-form-item label="图片">
            <el-image
              class="view-img"
              v-for="(item, i) in fileList"
              :key="i"
              :src="item"
              :preview-src-list="fileList"
            >
              <div slot="error" class="image-slot">
                暂无图片
              </div>
            </el-image>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!--      净化设备清洗维保记录-->
      <h2>六、净化设备清洗维保记录</h2>
      <div style="margin-bottom: 17px">
        <el-table
          :data="washData.content"
          border
          size="mini"
          style="width: 100%"
        >
          <el-table-column type="index" label="序号" align="center" />
          <el-table-column align="center" prop="LocaleName" label="监测点" ></el-table-column>
          <el-table-column
            align="center"
            prop="LocaleAddr"
            label="安装地址"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="CreatedAt"
            label="提交日期"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="BeginDate"
            label="起始日期"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="EndDate"
            label="终止日期"
          ></el-table-column>
          <el-table-column
            align="center"
            label="操作"
          >
            <template slot-scope="scope">
              <i
                title="查看"
                @click="lookWash(scope.row)"
                class="opt el-icon-orange el-icon-view"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
        <!--设备清洗记录查看-->
        <el-dialog
          :title="dlg1.title"
          :visible.sync="dlg1.visible"
        >
          <el-form
            :model="formData"
            label-width="140px"
            ref="form"
            size="mini"

          >
            <el-form-item prop="LocaleId" label="监测点" style="margin-bottom: 30px">
              <el-select
                v-model="formData.LocaleName"
                placeholder="请选择监测点"
                filterable
                disabled
              >
              </el-select>
            </el-form-item>
            <el-form-item label="开始日期">
              <el-date-picker
                v-model="formData.BeginDate"
                value-format="yyyy-MM-dd"
                type="date"
                disabled
                style="margin-bottom: 30px"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="结束日期">
              <el-date-picker
                v-model="formData.EndDate"
                value-format="yyyy-MM-dd"
                type="date"
                disabled
                style="margin-bottom: 30px"
              ></el-date-picker>
            </el-form-item>
            <el-form-item prop="UploadType" label="类型">
              <el-select v-model="formData.UploadType" disabled style="margin-bottom: 30px">
                <el-option label="净化器清洗" value="FilterWash" ></el-option>
                <el-option
                  label="歇业、停业申请"
                  value="BusinessStopped"
                ></el-option>
                <el-option
                  label="投诉"
                  value="Complaint"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="Remark" label="备注">
              <el-input v-model.trim="formData.Remark" disabled style="margin-bottom: 30px"/>
            </el-form-item>
            <!--          <el-form-item>-->
            <!--            <el-upload-->
            <!--              accept=".jpg,.jpeg,.png"-->
            <!--              :show-file-list="false"-->
            <!--              :action="upload()"-->
            <!--              :on-success="handleAvatarSuccess"-->
            <!--              :limit="4"-->
            <!--            >-->
            <!--              <i class="el-icon-plus avatar-uploader-icon" />-->
            <!--              <div slot="tip">上传照片（最多4张，支持jpg、jpeg、png）</div>-->
            <!--            </el-upload>-->
            <!--          </el-form-item>-->
            <div class="img-list" v-if="fileList">
              <div
                class="single-img"
                v-for="(item, idx) in fileList"
                :key="idx"
                style="
              position: relative;
              display: inline-block;
              margin-right: 10px;
            "
              >
                <img :src="item" />
                <div class="remove-img el-icon-close" @click="removeImg(idx)"></div>
              </div>
            </div>
          </el-form>
        </el-dialog>

        <!--      生成下载PDF-->
        <el-button type="primary"
                   @click="downloadPdf"
                   :loading="downloading"
                   style="margin-left: 45%">
          生成并下载pdf
        </el-button>
    </el-dialog>



<!--    查看小时折算浓度-->
    <intime-hour-data-dialog
      :locale="hourData.locale"
      :visible="hourData.visible"
      @close="handleHourDataClose"/>
  </section>
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/markLine'
import {mapState} from 'vuex'
import {
  dateFormater,
  join,
  underline,
  getPname,
  export_json,
  base64ToBlob,
  referToMap,
  maintenanceStatusFormatter,
  maintenanceTypeFormatter, getUserInfo, ownerJoin
} from '@/util/index'
import _1 from '@/asset/img/1.png'
import _2 from '@/asset/img/2.png'
import _3 from '@/asset/img/3.png'
import _4 from '@/asset/img/4.png'
import _5 from '@/asset/img/5.png'
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmGeolocation from 'vue-baidu-map/components/controls/Geolocation'
import PointInfoDialog from "@/component/PointInfoDialog.vue";
import IntimeHourDataDialog from "@/component/IntimeHourDataDialog.vue";
import OfflineViewDialog from "@/component/offlineManagement/OfflineViewDialog.vue";
import dayjs from "dayjs";
import AlarmViewDialog from "@/component/alarmManagement/AlarmViewDialog.vue";
import conf from "@/config";

export default {

  name: 'Intime',
  components: {
    AlarmViewDialog,
    OfflineViewDialog,
    IntimeHourDataDialog,
    PointInfoDialog,
    chart: ECharts,
    BaiduMap,
    BmGeolocation
  },
  data() {
    return {
      formData: {},
      dlg1: {
        visible: false,
        title: ''
      },//净化器设备清洗查看弹窗
      downloading: false, // 生成并下载pdf按钮 加载状态
      washData:[],//净化设备维保记录
      operationalRecord:[],//运维记录
      alarmManagement:[],//报警管理记录
      HealthCodeRecordDialog: {//红码处理记录查看弹窗
        loading: false,
        visible: false,
        data: null
      },
      redCode:[],//红码处理记录
      record:[],//上下线记录
      viewVisible:false,//上下线记录查看弹窗
      id:null,//上下线记录查看弹窗
      viewMode:null,//上下线记录查看弹窗
      mapCenter: {lng: 120.306305, lat: 30.424877},
      mapZoom: 14,
      isShowNotificationDialog: false,
      device: null,
      fileList: [],
      referMap: {},
      changeState: false,
      index: 0, // 记录更新index
      sels: [], // 列表选中列
      now: null,
      exportLoading: false,
      loading: false,
      showTyp: true,
      datalist: {},
      intimeData: {},
      selDate: null,
      owner: null,
      mn: null,
      onlineNum: 0,
      localeNum: 0,
      deviceNum: 0,
      localeScope: null,
      pdfRecordVisible: false,
      pdfList: [],
      EmissionsSill: null,
      GranuleSill: null,
      HydrocarbonSill: null,
      LinkStatus: null,
      exportData: null,
      timeType: '1',
      startDate: null,
      endDate: null,
      columnVisibleOptions1: [
        {
          value: '1',
          label: '监测点'
        }, {
          value: '2',
          label: '安装地址'
        }, {
          value: '3',
          label: '设备编码'
        }, {
          value: '4',
          label: '采集时间'
        }, {
          value: '5',
          label: '风机状态'
        }, {
          value: '6',
          label: '风机电流'
        }, {
          value: '7',
          label: '净化器状态'
        }, {
          value: '8',
          label: '净化器电流'
        }, {
          value: '9',
          label: '风速'
        }, {
          value: '10',
          label: '温度'
        }, {
          value: '11',
          label: '湿度'
        }, {
          value: '12',
          label: '油烟实时浓度'
        }, {
          value: '13',
          label: '油烟折算浓度'
        }, {
          value: '14',
          label: '颗粒物实时浓度'
        }, {
          value: '15',
          label: '颗粒物折算浓度'
        }, {
          value: '16',
          label: '非甲烷总烃实时浓度'
        }, {
          value: '17',
          label: '非甲烷总烃折算浓度'
        }, {
          value: '18',
          label: '设备状态'
        }, {
          value: '19',
          label: '风量'
        }, {
          value: '20',
          label: '整体状态'
        }],
      columnVisibleOptions2: [
        {
          value: '1',
          label: '监测点'
        }, {
          value: '2',
          label: '安装地址'
        }, {
          value: '3',
          label: '设备编码'
        }, {
          value: '4',
          label: '采集时间'
        }, {
          value: '5',
          label: '风机状态'
        }, {
          value: '6',
          label: '风机电流'
        }, {
          value: '7',
          label: '净化器状态'
        }, {
          value: '8',
          label: '净化器电流'
        }, {
          value: '9',
          label: '风速'
        }, {
          value: '10',
          label: '温度'
        }, {
          value: '11',
          label: '湿度'
        }, {
          value: '18',
          label: '设备状态'
        }, {
          value: '19',
          label: '风量'
        }, {
          value: '20',
          label: '整体状态'
        }],
      tenMinOption: {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['油烟浓度', '颗粒物浓度', '非甲烷总烃浓度']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: [],
          boundaryGap: false,
          axisLabel: {
            interval: 17
          }
        },
        yAxis: {
          type: 'value',
          // min: 5,
          // data : [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4,5],
          name: '平均浓度mg/m³'
        },
        series: [
          {
            name: '油烟浓度',
            type: 'line',
            data: [],
            itemStyle: {
              normal: {
                color: '#00A1FF',
                lineStyle: {
                  type: 'solid',
                  color: '#00A1FF'
                }
              }
            },
            markLine: {
              symbol: 'none',
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: 'solid',
                    color: '#00A1FF'
                  },
                  label: {
                    position: 'start',
                    formatter: '{c} '
                  }
                }
              },
              data: [
                {
                  name: '阈值',
                  yAxis: 1
                }
              ]
            }
          },
          {
            name: '颗粒物浓度',
            type: 'line',
            data: [],
            itemStyle: {
              normal: {
                color: '#F5B049',
                lineStyle: {
                  type: 'solid',
                  color: '#F5B049'
                }
              }
            },
            markLine: {
              symbol: 'none',
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: 'solid',
                    color: '#F5B049'
                  },
                  label: {
                    position: 'start',
                    formatter: '{c} '
                  }
                }
              },
              data: [
                {
                  name: '阈值',
                  yAxis: 1
                }
              ]
            }
          },
          {
            name: '非甲烷总烃浓度',
            type: 'line',
            data: [],
            itemStyle: {
              normal: {
                color: '#009999',
                lineStyle: {
                  type: 'solid',
                  color: '#009999'
                }
              }
            },
            markLine: {
              symbol: 'none',
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: 'solid',
                    color: '#009999'
                  },
                  label: {
                    position: 'start',
                    formatter: '{c} '
                  }
                }
              },
              data: [
                {
                  name: '阈值',
                  yAxis: 1
                }
              ]
            }
          }
        ]
      },
      dlg: {
        visible: false,
        state: null,
        title: ''
      },
      managementDialog: {
        visible: false,
        innerVisible: false,
        title: '监测点设备信息及事件记录',
        loading: false,
        deviceData: null,
        maintenanceData: {},
        innerData: {}
      },
      filter: {
        onlineStatus: null,
        name: null,
        owner: [],
        by: 'status',
        mode: 'asc',
        status: null,
        typ: 2,
        field: 'name',
        page: 1,
        intimePage: 1,
        size: 20,
        intimeSize: 20,
        pageSizes: [20, 30, 50]
      },
      columnVisible: ['1', '2', '4', '5', '7', '12', '13', '14', '15', '16', '17', '18', '20'],//这是一开始的table展示的数据
      FanFilterStatusOptions: {
        0: '关',
        1: '开',
        2: '-'
      },
      hourData: {
        visible: false,
        locale: {}
      }
    }
  },
  computed: {
    ...mapState({
      orgProps: (state) =>
        Object.assign({}, state.props, {label: 'Org', checkStrictly: true})
    }),
    ...mapState([
      'props',
      'clientHeight',
      'customer',
      'customerTree',
      'locale'
    ])
  },
  filters: {
    linkFilter(type) {
      const obj = {
        0: '关',
        1: '开',
        2: '-'
      }
      return obj[type]
    }
  },
  created() {
    this.user = getUserInfo()
    if (this.user.Info && this.user.Info.pid && this.user.Info.pid.slice(0,6) == "/1199/") {
      this.columnVisible = ['1', '2', '4', '12', '13', '14', '15', '16', '17', '18', '20']
    }
    this.now = Date.parse(new Date()) / 1000
    this.$get('admin/listCascadeReferParam', {
      pcode: 'DEVICE_TYPE',
      code: 'DEVICE_SUBTYPE',
      c_code: 'DEVICE_MODEL'
    }).then(res => {
      this.referMap = referToMap(res, 'Id', 'Name')
      res.forEach(e => {
        if (e.Pid !== '') {
          e.Pid = '/' + e.Pid + '/'
        }
      })
    })
    this.getData()
  },
  methods: {
    //查看清洗记录
    lookWash:function(scope){
      this.dlg1.title = '查看'
      this.dlg1.visible = true
      this.fileList = scope.LocaleUploadedInfoPicS
      this.formData = Object.assign({}, scope)
    },
    upload() {
      return conf.uploadApi + '/uploadToOss'
    },
    handleAvatarSuccess(res, file) {
      if (this.fileList.length < 4) {
        this.fileList.push(res.Data)
      } else {
        this.$message.warning(`照片数量限制在4张`)
      }
    },
    removeImg(idx) {
      //   this.changeState = true;
      this.fileList.splice(idx, 1)
    },
    //新增下载PDF方法
    downloadPdf() {
      this.downloading = true
      this.ExportSavePdf(this.$refs.pointInformationDialog, '监测点设备信息及事件记录').then(
        () => {
          setTimeout(() => {
            this.downloading = false
            this.handleClose()
          }, 500)
        }
      )
    },
    /**
     * @description 关闭对话框
     */
    handleClose() {
      this.$emit('on-close', false)
    },
    // 上下线记录操作查看的弹窗
    handleView(row) {
      this.id = row.id
      this.viewVisible = true
      this.viewMode = 'view'
    },
    // 关闭上下线记录操作查看的弹窗
    handleViewClose() {
      this.viewVisible = false
      this.getData1()
    },
    getData1() {
      this.loading = true
      let param = Object.assign({}, this.filter)
      if (param.owner.length !== 0) {
        let userInfo = getUserInfo().Info
        param.owner = ownerJoin(param.owner, userInfo)
      }

      if (param.start_time) {
        param.end_time = dayjs(param.start_time).add(1, 'day').unix()
        param.start_time = dayjs(param.start_time).unix()
      }

      this.$get('admin/offline_apply', param).then(res => {
        this.tableData = res.list
        this.total = res.count
        this.loading = false
      })
    },
    // 红码处理记录
    handleShowHealthCodeRecord(row) {
      this.healthCodeInfo = row
      this.getHealthCodeRecode()
    },
    getHealthCodeRecode() {
      this.HealthCodeRecordDialog.loading = true
      this.HealthCodeRecordDialog.visible = true
      const payload = {
        Param: {}
      }
      if (this.healthCodeInfo.LocaleId) {
        payload.Param['health_code_maintenance.locale_id'] = {S: 0, V: `'${this.healthCodeInfo.LocaleId}'`}
      }
      this.$post('admin/listHealthCodeRecord', payload).then(res => {
        this.HealthCodeRecordDialog.data = res.content
        this.HealthCodeRecordDialog.loading = false
      })
    },
    //报警管理查看弹窗
    handleCodeView(id, mode) {
      this.$refs['alarmViewDialog'].dealDialog(mode, { id: id })
    },

    //导出
    maintenanceStatusFormatter,
    maintenanceTypeFormatter,
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.datalist.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleDownload(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    handleMapReady() {
      if (this.managementDialog.innerVisible.Lng && this.managementDialog.innerVisible.Lat) {
        this.mapCenter = {lng: this.managementDialog.innerVisible.Lng, lat: this.managementDialog.innerVisible.Lat}
      }
    },
    getPosition(pos) {
      this.managementDialog.innerVisible.Lng = pos.point.lng.toString()
      this.managementDialog.innerVisible.Lat = pos.point.lat.toString()
      this.managementDialog.innerVisible.Position = pos.addressComponent['province'] + pos.addressComponent['city'] +
        pos.addressComponent['district'] + pos.addressComponent['street'] +
        pos.addressComponent['streetNumber']
    },
    //监测点设备管理  获取设备信息
    handleClickMaintenanceManagement(row) {
      const flag = [0, 0]
      const para = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: this.filter.size,
        FormType: 1,
        Param: {}
      }
      this.managementDialog.visible = true
      para.Param['maintenance.locale_id'] = {S: 2, V: row.Lid.toString()}
      this.managementDialog.loading = true
      this.$post('admin/getDevicesOfLocale', {LocaleId: row.Lid})
        .then(res => {
          this.managementDialog.deviceData = res
          flag[0] = 1
          if (flag[1] === 1) {
            this.managementDialog.loading = false
          }
        })
      //运维记录
      this.$post('admin/listMaintenance', para)
        .then(res => {
          this.managementDialog.maintenanceData = res
          flag[1] = 1
          if (flag[0] === 1) {
            this.managementDialog.loading = false
          }
        })
      this.$get('admin/offline_apply',{"locale_id": row.Lid}).then(res => {//{"locale_id":this.LocaleId}
        //list.push(res)
        this.record = res
      }).catch(() => {

      })
      //5月13新增红码处理记录
      const payload = {
        Param: {}
      }
      if (row.Lid) {
        payload.Param['health_code_maintenance.locale_id'] = {S: 0, V: `'${row.Lid}'`}
      }
      this.$post('admin/listHealthCodeRecord',payload).then((res) => {//{"locale_id": data.Lid}
        //list.push(res)
        this.redCode = res.content
      }).catch(() => {

      })
      //5月13新增报警管理记录
      this.$get("admin/alarmManagement/list",{"owner":row.Owner}).then(res => {
        //list.push(res)
        this.alarmManagement = res
      }).catch(() => {

      })

      //修改获取净化设备清洗维保记录bug
      this.$post('admin/queryLocaleUploadedInfo', {"LocaleName": row.Locale}).then((res) => {
        //list.push(res)
        this.washData = res
      }).catch(() => {
      })

    },
    electricityFormatter(row, type) {
      switch (type) {
        case 'fan':
          if (row.FanStatus.toString() === '1') {
            return row.fan_current
          } else {
            return '-'
          }
        case 'filter':
          if (row.FilterStatus.toString() === '1') {
            return row.pur_current
          } else {
            return '-'
          }
      }
    },
    selectAll() {
      this.columnVisible = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20']
      this.afterChangeColumnVisiable()
    },
    afterChangeColumnVisiable() {
      this.$nextTick(() => {
        this.$refs['table'].doLayout()
      })
    },
    handleType() {
      this.filter.page = 1
      this.getData()
    },
    handleDateSel(v) {
      if (v !== null) {
        if (this.showTyp) {
          this.getTenMinData()
        } else {
          this.getIntimeData()
        }
      }
    },
    handleSort(c) {
      if (c.order) {
        if (c.prop === 'DataTime') {
          this.filter.by = 'acquit_at'
        } else {
          this.filter.by = underline(c.prop)
        }
        this.filter.mode = c.order.replace(/ending/g, '')
      }
      this.getData()
    },
    /**
     * @return {string}
     */
    FanFilterStatusFormatter(row, type) {
      switch (type) {
        case 0:
          return this.FanFilterStatusOptions[row.FanStatus]
        case 1:
          return this.FanFilterStatusOptions[row.FilterStatus]
      }
    },
    managementDateFormatter(r, c) {
      return dateFormater(r[c.property], false, false)
    },
    dateFormat(r, c) {
      return dateFormater(r[c.property], true)
    },
    fanFilterFormatter(r) {
      if (r.LinkStatus === 1) {
        return '开' // r.Status === 'EXCESS' || r.Status === 'NORMAL' || r.Status === 'FAULT' ? '开' : '-'
      }
      return '-'
    },
    ffFormatter(r) {
      if (this.LinkStatus === 1) {
        return '开'
      }
      return '-'
    },
    modelFormatter(r, c) {
      return this.referMap['c' + r[c.property]]
    },
    subTypeFormatter(r, c) {
      return this.referMap['d' + r[c.property]]
    },
    customerFormatter(r, c) {
      return getPname(this.customerTree, r[c.property], 'Org').join('/')
    },
    getIntimeData() {
      this.loading = true
      this.$post('admin/listIntimeData', {
        StartAt: (this.filter.intimePage - 1) * this.filter.intimeSize,
        Size: this.filter.intimeSize,
        mn: this.mn,
        typ: this.filter.typ,
        at: this.selDate / 1000
      }).then((res) => {
        this.loading = false
        this.intimeData = res
        if (
          res &&
          res.content &&
          res.content.length !== 0 &&
          this.selDate === null
        ) {
          this.selDate = new Date(
            new Date(res.content[0]['acquit_at'] * 1000).setHours(0, 0, 0, 0)
          ).getTime()
        }
      })
    },
    // 一点一档--历史记录
    handlePdfRecordList(row) {
      // this.localeId = row.Id;
      this.pdfRecordVisible = true
      this.pdfList = []
      this.$post('admin/queryLocaleArchive', {LocaleId: row.Lid})
        .then((res) => {
          this.pdfList = res
        })
        .catch(() => {
        })
    },
    handleManagementLook(row) {
      this.managementDialog.innerVisible = true
      this.managementDialog.innerData = Object.assign({}, row, {
        MaintenancePics: []
      })
      this.fileList = row.MaintenancePicS
    },
    // 查看历史一点一档
    handleLook(row) {
      this.download(row.LocaleId, row.Archive)
    },
    // 下载图片
    download(name, imgData) {
      this.downloadFile(name, imgData)
    },
    // 下载
    downloadFile(fileName, content) {
      const aLink = document.createElement('a')
      const blob = base64ToBlob(content) // new Blob([content]);
      const evt = document.createEvent('HTMLEvents')
      evt.initEvent('click', true, true) // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName
      aLink.href = URL.createObjectURL(blob)
      // aLink.dispatchEvent(evt);
      // aLink.click()
      aLink.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      ) // 兼容火狐
    },
    getLast10Days() {
      const day = new Date()
      const yy = day.getFullYear()
      const mm =
        day.getMonth() + 1 < 10 ? `0${day.getMonth() + 1}` : day.getMonth() + 1
      const dd = day.getDate() < 10 ? `0${day.getDate()}` : day.getDate()
      const last = new Date(new Date().getTime() - 10 * 24 * 3600 * 1000)
      const lyy = last.getFullYear()
      const lmm =
        last.getMonth() + 1 < 10
          ? `0${last.getMonth() + 1}`
          : last.getMonth() + 1
      const ldd = last.getDate() < 10 ? `0${last.getDate()}` : last.getDate()
      this.startDate = `${lyy}-${lmm}-${ldd}`
      this.endDate = `${yy}-${mm}-${dd}`
    },
    getTenMinData() {
      this.loading = true
      let tpl, url
      if (this.timeType.toString() === '1') {
        tpl = {
          mn: this.mn,
          at: this.selDate / 1000
        }
        url = 'admin/listTenMinData'
      } else if (this.timeType.toString() === '2') {
        tpl = {
          mn: this.mn,
          AcquitAtTimeBegin: this.startDate,
          AcquitAtTimeEnd: this.endDate
        }
        url = 'admin/queryTenMinData'
      }
      this.$post(url, tpl).then((res) => {
        this.loading = false
        this.exportData = []
        this.tenMinOption.series[0]['data'] = []
        this.tenMinOption.series[0]['markLine']['data'][0]['yAxis'] = this.EmissionsSill
        this.tenMinOption.xAxis['data'] = []
        this.tenMinOption.series[1]['data'] = []
        this.tenMinOption.series[1]['markLine']['data'][0]['yAxis'] = this.GranuleSill
        this.tenMinOption.series[2]['data'] = []
        this.tenMinOption.series[2]['markLine']['data'][0]['yAxis'] = this.HydrocarbonSill
        if (res && res.length !== 0) {
          if (this.timeType * 1 === 1) {
            if (this.selDate === null) {
              this.selDate = new Date(
                new Date(res[0]['acquit_at'] * 600 * 1000).setHours(0, 0, 0, 0)
              ).getTime()
            }
            let data
            const t = new Date(this.selDate)
            t.setHours(0, 0, 0, 0)
            const endTime = Math.floor(t.getTime() / 600000) + 143
            let at
            for (var i = Math.floor(t.getTime() / 600000); i <= endTime; i++) {
              if (this.timeType.toString() === '2'){
                data = res.data.find((e) => e.acquit_at === i)
              }else{
                data = res.find((e) => e.acquit_at === i)
              }
              at = dateFormater(i * 600, true, true)
              if (!data) {
                data = {
                  emissions_conc: 0,
                  granule_conc: 0,
                  hydrocarbon_conc: 0,
                  counter: 1
                }
              } else {
                this.exportData.push({
                  采集时间: at,
                  油烟平均浓度: (data.emissions_conc).toFixed(2),
                  颗粒物平均浓度: (data.granule_conc).toFixed(2),
                  非甲烷总烃平均浓度: (
                    data.hydrocarbon_conc
                  ).toFixed(2)
                })
              }
              this.tenMinOption.series[0]['data'].push(
                (data.emissions_conc).toFixed(2)
              )
              this.tenMinOption.series[1]['data'].push(
                (data.granule_conc).toFixed(2)
              )
              this.tenMinOption.series[2]['data'].push(
                (data.hydrocarbon_conc).toFixed(2)
              )
              this.tenMinOption.xAxis['data'].push(at)
            }
          } else if (this.timeType * 1 === 2) {
            let data
            const begin = new Date(this.startDate)
            begin.setHours(0, 0, 0, 0)
            const end = new Date(this.endDate)
            end.setHours(0, 0, 0, 0)
            const endTime = Math.floor(end.getTime() / 600000)
            let at
            this.tenMinOption.xAxis['axisLabel']['interval'] = 287
            for (
              var i = Math.floor(begin.getTime() / 600000);
              i <= endTime;
              i++
            ) {
              if (this.timeType.toString() === '2'){
                data = res.data.find((e) => e.acquit_at === i)
              }else{
                data = res.find((e) => e.acquit_at === i)
              }
              at = dateFormater(i * 600, true, false)
              if (!data) {
                data = {
                  emissions_conc: 0,
                  granule_conc: 0,
                  hydrocarbon_conc: 0,
                  counter: 1
                }
              } else {
                this.exportData.push({
                  采集时间: at,
                  油烟平均浓度: (data.emissions_conc).toFixed(2),
                  颗粒物平均浓度: (data.granule_conc).toFixed(2),
                  非甲烷总烃平均浓度: (
                    data.hydrocarbon_conc
                  ).toFixed(2)
                })
              }
              this.tenMinOption.series[0]['data'].push(
                (data.emissions_conc).toFixed(2)
              )
              this.tenMinOption.series[1]['data'].push(
                (data.granule_conc).toFixed(2)
              )
              this.tenMinOption.series[2]['data'].push(
                (data.hydrocarbon_conc).toFixed(2)
              )
              this.tenMinOption.xAxis['data'].push(at)
            }
          }
          this.$forceUpdate()
        }
      })
    },
    // 查看每分钟的数据  查看10分钟浓度
    handleShowIntime(r, showTyp) {
      this.timeType = '1'
      this.mn = r.Id
      this.showTyp = showTyp
      this.owner = getPname(this.customerTree, r.Owner, 'Org').join('/')
      if (showTyp) {
        this.owner += '-10min浓度曲线'
        this.EmissionsSill = r.EmissionsSill
        this.GranuleSill = r.GranuleSill
        this.HydrocarbonSill = r.HydrocarbonSill
        this.getLast10Days()
        this.getTenMinData()
      } else {
        this.owner += '-分钟采集数据'
        this.LinkStatus = r.LinkStatus
        this.getIntimeData()
      }
      this.dlg.visible = true
    },
    handleExport() {
      if (this.showTyp) {
        export_json(
          dateFormater(this.selDate / 1000, false) + '-' + this.mn,
          this.exportData,
          {
            采集时间: '采集时间',
            油烟平均浓度: '油烟平均浓度',
            颗粒物平均浓度: '颗粒物平均浓度',
            非甲烷总烃平均浓度: '非甲烷总烃平均浓度'
          }
        )
      } else {
        this.$download('admin/exportIntimeData', {
          mn: this.mn,
          typ: this.filter.typ,
          at: this.selDate / 1000
        })
      }
    },
    getImg(type, status) {
      if (type === 'Status') {
        switch (status) {
          case 'NORMAL':
            return _1
          case 'EXCESS':
            return _2
          case 'DOWN':
            return _3
          case 'OFF':
            return _4
          case 'ALARM':
            return _5
          default:
            return _4
        }
      } else if (type === 'OnlineStatus') {
        switch (status) {
          case 1:
            return _1
          case 2:
            return _3
          case 3:
            return _4
          default:
            return _4
        }
      } else {
        return _4
      }
    },
    //每选择一项数据调用这个方法自动进行查询
    getData: function () {
      this.loading = true
      var para = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: this.filter.size,
        Typ: this.filter.typ,
        SortBy: this.filter.by,
        SortMode: this.filter.mode
      }
      para.Param = {
        StatusOfRecord: 'NORMAL'
      }
      if (this.filter.owner.length !== 0) {
        para.Param['Owner'] = ownerJoin(this.filter.owner,getUserInfo().Info)
      }
      if (this.filter.status) {
        para.Param['Status'] = this.filter.status
      }
      if (this.filter.onlineStatus) {
        para.Param['OnlineStatus'] = this.filter.onlineStatus
      }
      this.$post('admin/listDataIntime', para).then((res) => {
        if (res) {
          if (para.StartAt === 0) {
            this.localeNum = res.localeNum
            this.deviceNum = res.deviceNum
            this.datalist.total = res.total
          }
          this.onlineNum = res.onlineNum
          this.datalist.content = res.content
        }
        this.loading = false
      })
    },
    //刷新功能
    handleRefresh() {
      this.filter.page = 1
      // this.filter.owner = [];
      this.getData()
    },
    handlePageChange(val, field, typ) {
      this.filter[field] = val
      if (typ) {
        this.getData()
      } else {
        this.getIntimeData()
      }
    },
    handlerClose() {
      this.selDate = null
      this.filter.intimePage = 1
    },
    handleDownload(exportSize) {
      this.exportLoading = true
      const statusMap = {
        1: '开',
        0: '关',
        2: '未连接'
      }
      const status = {
        'NORMAL': '达标',
        'EXCESS': '超标',
        'DOWN': '离线',
        'OFF': '异常离线'
      }
      var para = {
        StartAt: 0,
        Size: exportSize,
        Typ: this.filter.typ,
        SortBy: this.filter.by,
        SortMode: this.filter.mode
      }
      para.Param = {
        StatusOfRecord: 'NORMAL',
        IsDownload: true
      }
      if (this.filter.owner.length !== 0) {
        para.Param['Owner'] = join(this.filter.owner)
      }
      if (this.filter.status) {
        para.Param['Status'] = this.filter.status
      }
      if (this.filter.onlineStatus) {
        para.Param['OnlineStatus'] = this.filter.onlineStatus
      }
      this.$post('admin/listDataIntime', para).then(res => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
      })
        .then(() => {
          this.exportLoading = false
        })
    },
    handleFileClick(value) {
      this.device = value;
      this.isShowNotificationDialog = true
    },
    /**
     * 小时折算浓度对话框可视
     * @param row
     */
    handleHourDataVisible(row) {
      this.hourData.locale = row
      this.hourData.visible = true

    },
    handleHourDataClose() {
      this.hourData.visible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.single-img > img {
  width: 160px;
  height: 160px;
}
table.device-info {
  display: table;
}

.red {
  color: red;
}
.el-icon-orange {
  color: #409eff;
}
</style>
